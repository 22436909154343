import React from "react";

const GDPRPolicy = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="font-montserrat w-full md:w-2/3 px-5 md:px-60 py-10 text-secondary">
        <h3 className="text-heading">GDPR Compliance Policy</h3>
        <p>
          At <b>InfiniteB2B</b>, we are committed to protecting the privacy and
          data of our users in compliance with the General Data Protection
          Regulation (GDPR). This policy outlines how we collect, process, and
          protect your personal information when you use our portal and
          services.
        </p>
        <hr />
        <h5 className="text-heading">1. Data Controller</h5>
        <p>
          InfiniteB2B is the data controller responsible for the processing of
          your personal data collected through our portal.
        </p>
        <hr />
        <h5 className="text-heading">2. Data Collection</h5>
        <p>
          We collect personal information that you voluntarily provide when you:
        </p>
        <ul>
          <li>
            <p>• Register an account on our portal.</p>
          </li>
          <li>
            <p>• Use our services or request support.</p>
          </li>
          <li>
            <p>• Subscribe to updates, newsletters, or other communications.</p>
          </li>
        </ul>
        <p>The types of data we collect may include:</p>
        <ul>
          <li>
            <p>• Name, email address, phone number, and company details.</p>
          </li>
          <li>
            <p>• Login credentials for accessing your account.</p>
          </li>
          <li>
            <p>• Usage data and interaction details on the portal.</p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">3. Legal Basis for Data Processing</h5>
        <p>We process personal data based on the following lawful grounds:</p>
        <ul>
          <li>
            <p>
              • <b>Consent:</b> When you explicitly agree to the use of your
              data.
            </p>
          </li>
          <li>
            <p>
              • <b>Contractual Necessity:</b> When processing is required to
              fulfill our services.
            </p>
          </li>
          <li>
            <p>
              • <b>Legal Obligations:</b> When we need to comply with applicable
              laws.
            </p>
          </li>
          <li>
            <p>
              • <b>Legitimate Interest:</b> When processing is necessary to
              improve our portal and services.
            </p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">4. Data Usage</h5>
        <p>We use your data for the following purposes:</p>
        <ul>
          <li>
            <p>• To provide and improve our portal and services.</p>
          </li>
          <li>
            <p>
              • To communicate with you, respond to inquiries, and provide
              updates.
            </p>
          </li>
          <li>
            <p>• To ensure the security and integrity of the portal.</p>
          </li>
          <li>
            <p>• To comply with legal obligations and enforce our terms.</p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">5. Data Sharing</h5>
        <p>
          We do not sell, trade, or share your personal data with third parties,
          except in the following cases:
        </p>
        <ul>
          <li>
            <p>
              • With service providers that assist in operating our portal
              (e.g., hosting and email services).
            </p>
          </li>
          <li>
            <p>• To comply with legal obligations or protect our rights.</p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">6. Data Retention</h5>
        <p>
          We retain your personal data only as long as necessary for the
          purposes outlined in this policy, unless a longer retention period is
          required by law.
        </p>
        <hr />
        <h5 className="text-heading">7. Your Rights Under GDPR</h5>
        <p>As an EU/EEA user, you have the following rights:</p>
        <ul>
          <li>
            <p>
              • <b>Access:</b> Request a copy of your personal data.
            </p>
          </li>
          <li>
            <p>
              • <b>Rectification:</b> Correct inaccurate or incomplete data.
            </p>
          </li>
          <li>
            <p>
              • <b>Erasure:</b> Request the deletion of your data, also known as
              the "right to be forgotten."
            </p>
          </li>
          <li>
            <p>
              • <b>Restriction:</b> Request the restriction of processing under
              certain circumstances.
            </p>
          </li>
          <li>
            <p>
              • <b>Portability:</b> Request your data in a structured, commonly
              used format.
            </p>
          </li>
          <li>
            <p>
              • <b>Objection:</b> Object to the processing of your data for
              specific purposes.
            </p>
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at
          support@infiniteb2b.com.
        </p>
        <hr />
        <h5 className="text-heading">8. Data Security</h5>
        <p>
          We implement robust security measures to protect your data from
          unauthorized access, loss, or misuse. These include encryption, secure
          servers, and regular security audits.
        </p>
        <hr />
        <h5 className="text-heading">9. Cookies and Tracking</h5>
        <p>
          Our portal uses cookies to enhance your experience and track usage
          patterns. You can manage or disable cookies through your browser
          settings. For more information, please refer to our Cookie Policy.
        </p>
        <hr />
        <h5 className="text-heading">10. Third-Party Links</h5>
        <p>
          The portal may contain links to external websites. We are not
          responsible for the privacy practices of these sites and encourage you
          to review their policies.
        </p>
        <hr />
        <h5 className="text-heading">11. Changes to This Policy</h5>
        <p>
          We may update this GDPR policy from time to time to reflect changes in
          our practices or legal requirements. Please review this page
          periodically for updates.
        </p>
        <hr />
        <h5 className="text-heading">12. Contact Us</h5>
        <p>
          If you have any questions, concerns, or requests regarding this policy
          or the processing of your personal data, please contact us:
        </p>
        <p className="underline text-heading">
          <b>Email:</b> support@infiniteb2b.com
        </p>
        <hr />
      </div>
    </div>
  );
};

export default GDPRPolicy;
