import React, { useEffect, useState } from "react";
import ProfileSidebar from "../../../components/ProfileSidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";

export default function ProfileNewsletter() {
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/newsletter-status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          setIsSubscriber(response.data.data?.isNewsLetterSubscriber);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleSaveClick = () => {
    // Open the confirmation modal
    setIsModalOpen(true);
  };

  const confirmSave = async () => {
    setIsModalOpen(false); // Close the modal after confirmation
    try {
      const formData = new FormData();
      formData.append("isSubscriber", isSubscriber);

      const response = await axios.post(
        `${apiUrl}/user/newsletter-subscribe`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        alert("Newsletter subscription updated successfully.");
      } else {
        alert("Failed to update newsletter subscription.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while saving your subscription.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className="p-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left">Newsletter</h3>
          <div className="flex flex-col gap-4">
            <h3 className="text-3xl text-left font-semibold">
              Subscribed to newsletter
            </h3>
            <select
              name="isSubscriber"
              value={isSubscriber}
              onChange={(e) => setIsSubscriber(e.target.value === "true")}
              className="block w-[200px] rounded-md border-gray-400 bg-white py-2 px-3 text-base shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <button
              onClick={handleSaveClick}
              className="text-white w-fit py-1 px-5 bg-gradient-to-r from-[#3F179B] to-[#5493A4] rounded"
            >
              Save
            </button>
          </div>
        </div>
      </section>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md text-center">
            <p className="text-gray-700 mb-4">
              Are you sure you want to update your newsletter subscription
              status?
            </p>
            <div className="flex justify-center gap-4">
              <button
                className="py-1 px-4 bg-gradient-to-r from-[#3F179B] to-[#5493A4] text-white rounded"
                onClick={confirmSave}
              >
                Yes, Save
              </button>
              <button
                className="py-1 px-4 bg-gray-300 text-gray-700 rounded"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
