import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: sessionStorage.getItem("user"),
  token: sessionStorage.getItem("token"),
  profilePicture: sessionStorage.getItem("profilePicture"),
  loading: false,
  error: null,
};

export const useSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { username, jwtToken, profilePicture } = action.payload;
      state.username = username;
      state.token = jwtToken;
      state.profilePicture = profilePicture;
      sessionStorage.setItem("token", jwtToken);
      sessionStorage.setItem("user", username);
      sessionStorage.setItem("profilePicture", profilePicture);
    },
    setUsername: (state, action) => {
      const { username } = action.payload;
      state.username = username;
      sessionStorage.setItem("user", username);
    },
    setProfilePicture: (state, action) => {
      const { profilePicture } = action.payload;
      state.profilePicture = profilePicture;
      sessionStorage.setItem("profilePicture", profilePicture);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.loading = action.payload;
    },
    clearUser: (state, action) => {
      state.username = "";
      state.token = "";
      state.profilePicture = "";
      sessionStorage.clear();
    },
  },
});

export const { setUser, clearUser, setUsername } = useSlice.actions;

export default useSlice.reducer;
