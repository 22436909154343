import { useNavigate } from "react-router-dom";
import ProfileSidebar from "../../../components/ProfileSidebar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";

export default function ProfileDashboard() {
  const [dashboardData, setDashboardData] = useState({});

  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/count-dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });
        if (response.data.status) {
          setDashboardData(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="w-full flex ">
        <ProfileSidebar />
        <div className=" p-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left ">Dashboard</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5 text-white py-3">
            <div
              onClick={() => navigate("/saved-whitepapers")}
              className="bg-[#4B49A2] rounded-2xl flex flex-col justify-center items-center py-10 md:py-16 shadow-md shadow-[#666565]  cursor-pointer"
            >
              <h1 className="text-4xl md:text-6xl">
                {dashboardData?.savedWhitePapersCount || 0}
              </h1>
              <h3>Saved Whitepapers</h3>
            </div>
            <div
              onClick={() => navigate("/subscribed-category")}
              className="bg-[#4B49A2] rounded-2xl flex flex-col justify-center items-center py-10 md:py-16 shadow-md shadow-[#666565] cursor-pointer"
            >
              <h1 className="text-4xl md:text-6xl">
                {dashboardData?.subscribedCategory || 0}
              </h1>
              <h3>Subscribed Category</h3>
            </div>
            <div
              onClick={() => navigate("/profile-newsletter")}
              className="bg-[#4B49A2] rounded-2xl flex flex-col justify-center items-center py-10 md:py-16 shadow-md shadow-[#666565] cursor-pointer"
            >
              <h1 className="text-4xl md:text-6xl">
                {dashboardData?.newsLetterSubscribed ? "Yes" : "No"}
              </h1>
              <h3>Newletter</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
