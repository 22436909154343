import React, { useEffect, useState } from "react";
import ProfileSidebar from "../../../components/ProfileSidebar";
import { FeatureCard } from "../../../components/FeatureCard";
import { ChevronLeft, ChevronRight } from "react-feather";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";

export default function SavedWhitepapers() {
  const [savedWhitepapers, setSavedWhitepapers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const token = useSelector((state) => state.user.token);

  const cardsPerPage = 8;

  const totalPages = Math.ceil(savedWhitepapers.length / cardsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentPageCards = () => {
    const startIndex = currentPage * cardsPerPage;
    return savedWhitepapers.slice(startIndex, startIndex + cardsPerPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/allwhitepapers-saved`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );
        if (response.data.status) {
          setSavedWhitepapers(response.data.data.whitepapers);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className=" p-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left">Saved Whitepapers</h3>
          <div className="relative px-4 lg:px-28 mt-8">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2  lg:grid-cols-4  md:gap-3 lg:gap-4">
              {getCurrentPageCards()?.map((featureCard, index) => (
                <FeatureCard key={index} featureCard={featureCard} />
              ))}
            </div>
            {/* Navigation Controls */}
            <div className="flex justify-center items-center mt-8 gap-4">
              <button
                onClick={handlePrevious}
                className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
              >
                <ChevronLeft size={24} />
              </button>

              {/* Pagination Dots */}
              <div className="flex items-center gap-2">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <div
                    key={index}
                    className={`h-3 w-3 rounded-full ${
                      currentPage === index ? "bg-purple-800" : "bg-gray-300"
                    }`}
                  />
                ))}
              </div>

              <button
                onClick={handleNext}
                className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
              >
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
