import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/constant";
import { toast } from "react-toastify";

const useSubscribe = (id, isSubscribeByUser) => {
  const [isSubscribe, setIsSubscribe] = useState(isSubscribeByUser);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    setIsSubscribe(isSubscribeByUser);
  }, [isSubscribeByUser]);

  const handleSubscribeClick = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("categoryId", id);

      const response = await axios.post(
        `${apiUrl}/user/category/subscribe`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        }
      );

      if (response.data.status) {
        setIsSubscribe(!isSubscribe);
        if (!isSubscribe) {
          toast.success("Subscribed Categary");
        } else {
          toast.warning("Unsubscribed Categary");
        }
      } else {
        console.error(
          "Failed to save the whitepaper: " + response.data.message
        );
      }
    } catch (error) {
      console.error("An error occurred while saving the whitepaper.", error);
    }
  };

  return { isSubscribe, handleSubscribeClick };
};

export default useSubscribe;
