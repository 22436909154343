import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Search,
  Bell,
  Menu,
  ChevronDown,
  User,
  LogOut,
  Bookmark,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../app/slices/userSlice";
import { imgUrl } from "../utils/constant";

const Header = () => {
  const dispatch = useDispatch();
  const [showInput, setShowInput] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const username = useSelector((state) => state.user.username);
  const profilePicture = useSelector((state) => state.user.profilePicture);

  //
  const location = useLocation();

  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  const handleClickOutside = (e) => {
    if (
      !e.target.closest(".menu-container") &&
      !e.target.closest(".menu-toggle") &&
      !e.target.closest(".dropdown-container")
    ) {
      setMenuOpen(false);
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <header
      className={`px-4 py-3 ${
        isHomePage
          ? "absolute top-0 left-0 z-10 w-full bg-transparent"
          : "relative shadow-md bg-gradient-to-r from-[#4702A2] to-[#4F88A2]"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-8">
          <img
            src={require("../assets/Infinite-b2b-1-scaled.png")}
            alt="Logo"
            className="h-14 w-auto cursor-pointer"
            style={{
              // height: "48px",
              // width: "48px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          />

          <nav className="hidden lg:flex space-x-8">
            <button
              className="text-white hover:underline hover:font-bold font-montserrat"
              onClick={() => navigate("/home")}
            >
              HOME
            </button>
            <button
              className="ml-2 text-white hover:underline hover:font-bold font-montserrat"
              onClick={() => navigate("/whitepapers-set")}
            >
              WHITEPAPERSET
            </button>
            <button
              className="text-white hover:underline hover:font-bold font-montserrat"
              onClick={() => navigate("/newsletters")}
            >
              NEWSLETTERS
            </button>
            <button
              className="text-white hover:underline hover:font-bold w-full font-montserrat"
              onClick={() => {
                setMenuOpen(!menuOpen);
                navigate("/blogs");
              }}
            >
              BLOGS
            </button>
          </nav>
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center text-white">
            {showInput && (
              <input
                type="text"
                placeholder="Search..."
                className="bg-transparent border-b border-white text-white text-sm outline-none mr-2"
                autoFocus
              />
            )}
            <div
              onClick={() => setShowInput(!showInput)}
              className="hidden md:block cursor-pointer"
            >
              <Search className="h-5 w-5" />
            </div>
          </div>

          {token && (
            <div className="relative text-white">
              <Bell className="h-5 w-5 cursor-pointer" />
              <div className="font-montserrat absolute -top-2 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                3
              </div>
            </div>
          )}

          {token && (
            <div
              className="relative flex items-center space-x-2 dropdown-container cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="p-[2px] rounded-full">
                {profilePicture ? (
                  <div
                    style={{
                      backgroundImage: `url(${imgUrl + profilePicture})`,
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                ) : (
                  <User className="rounded-full text-gray-400 bg-gray-50" />
                )}
              </div>
              <div className="hidden md:flex items-center space-x-1 ">
                <span className="text-white font-semibold font-montserrat">
                  {username}
                </span>
                {/* <ChevronDown className="w-5 h-5 text-white" /> */}
              </div>

              {dropdownOpen && (
                <div className="absolute top-12 right-0 flex flex-col gap-1 bg-white shadow-lg rounded-md w-32 md:w-48 p-1 z-50">
                  <button
                    className="block text-left text-sm w-full text-gray-500 hover:bg-gray-100 px-2 py-2 rounded-md"
                    onClick={() => navigate("/profile-dashboard")}
                  >
                    <div className="flex items-center gap-2">
                      <User size={18} />
                      Profile
                    </div>
                  </button>
                  <button
                    className="block text-left text-sm w-full text-gray-500 hover:bg-gray-100 px-2 py-2 rounded-md"
                    onClick={() => navigate("/saved-whitepapers")}
                  >
                    <div className="flex items-center gap-2">
                      <Bookmark size={18} />
                      Saved Whitepaper
                    </div>
                  </button>
                  <button
                    className="block text-left text-sm w-full text-gray-500 hover:bg-gray-100 px-2 py-2 rounded-md"
                    onClick={() => navigate("/subscribed-category")}
                  >
                    <div className="flex items-center gap-2">
                      <Bell size={18} />
                      Subscribed Category
                    </div>
                  </button>
                  <button
                    className="block text-left w-full text-sm text-red-500 hover:bg-red-100 px-2 py-2 rounded-md"
                    onClick={() => {
                      console.log("Logged out");
                      dispatch(clearUser());
                      navigate("/home");
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <LogOut size={18} />
                      Log Out
                    </div>
                  </button>
                </div>
              )}
            </div>
          )}

          {!token && (
            <button
              className="bg-white text-[#4702a2] text-sm font-semibold px-6 py-2 rounded-2xl border border-white hover:bg-teal-400 hover:text-teal-500 lg:block hidden font-montserrat"
              onClick={() => navigate("/login")}
            >
              SIGN IN
            </button>
          )}

          <div className="lg:hidden menu-toggle">
            <Menu
              className="mr-1 h-6 w-6 text-white cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="lg:hidden menu-container absolute top-16 left-0 w-full bg-gradient-to-r from-[#4702A2] to-[#4F88A2] p-4 z-10">
          <nav className="space-y-4">
            <button
              className="text-white hover:underline hover:font-bold w-full text-sm font-montserrat"
              onClick={() => {
                setMenuOpen(!menuOpen);
                navigate("/home");
              }}
            >
              HOME
            </button>
            <button
              className="text-white hover:underline hover:font-bold w-full font-montserrat"
              onClick={() => {
                setMenuOpen(!menuOpen);
                navigate("/whitepapers-set");
              }}
            >
              WHITEPAPERS
            </button>
            <button
              className="text-white hover:underline hover:font-bold w-full font-montserrat"
              onClick={() => {
                setMenuOpen(!menuOpen);
                navigate("/newsletters");
              }}
            >
              NEWSLETTERS
            </button>
            <button
              className="text-white hover:underline hover:font-bold w-full font-montserrat"
              onClick={() => {
                setMenuOpen(!menuOpen);
                navigate("/newsletters");
              }}
            >
              BLOGS
            </button>

            {!token && (
              <button
                className=" text-[#3F179B] bg-white text-sm font-semibold w-full mt-2 py-2 rounded-sm"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  navigate("/login");
                }}
              >
                SIGN IN
              </button>
            )}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
