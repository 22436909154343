import React, { useState } from "react";
import ProfileSidebar from "../../../components/ProfileSidebar";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";
import InputPassword from "../../../components/InputPassword";

export default function ProfileChangePassword() {
  const token = useSelector((state) => state.user.token);

  const [formdata, setFormdata] = useState({
    oldPassword: "",
    password: "",
    password2: "",
  });

  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    isSuccess: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formdata?.oldPassword) {
      setModal({
        isOpen: true,
        message: "Please enter your old password.",
        isSuccess: false,
      });
      return;
    } else if (!formdata?.password || !formdata?.password2) {
      setModal({
        isOpen: true,
        message: "Please fill in both New Password and Confirm Password.",
        isSuccess: false,
      });
      return;
    } else if (formdata?.password !== formdata?.password2) {
      setModal({
        isOpen: true,
        message: "New Password and Confirm Password do not match.",
        isSuccess: false,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("oldpassword", formdata.oldPassword);
      formData.append("newpassword", formdata.password);

      const response = await axios.post(
        `${apiUrl}/user/update-password`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        setModal({
          isOpen: true,
          message: "Password changed successfully.",
          isSuccess: true,
        });
      } else {
        setModal({
          isOpen: true,
          message: response.data.message,
          isSuccess: false,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setModal({
        isOpen: true,
        message: "An error occurred while changing the password.",
        isSuccess: false,
      });
    }
  };

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className="px-5 pt-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left">Change Password</h3>
          <form className="w-full md:w-1/2">
            <div className="flex flex-col gap-2 mb-3">
              <label htmlFor="oldPassword">Old Password</label>
              <InputPassword
                type="password"
                className="py-1 px-2 rounded outline-none w-full"
                placeholder="Old Password"
                name="oldPassword"
                value={formdata?.oldPassword}
                onChange={(e) =>
                  setFormdata({ ...formdata, oldPassword: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 py-2 mb-3">
              <div className="flex flex-col gap-2">
                <label htmlFor="password">New Password</label>
                <InputPassword
                  type="password"
                  className="py-1 px-2 rounded outline-none w-full"
                  placeholder="New Password"
                  name="password"
                  value={formdata?.password}
                  onChange={(e) =>
                    setFormdata({ ...formdata, password: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="password2">Confirm Password</label>
                <InputPassword
                  type="password"
                  className="py-1 px-2 rounded outline-none w-full"
                  placeholder="Confirm Password"
                  name="password2"
                  value={formdata?.password2}
                  onChange={(e) =>
                    setFormdata({ ...formdata, password2: e.target.value })
                  }
                />
              </div>
            </div>
            <button
              className="text-white w-fit my-2 py-1 px-5 bg-gradient-to-r from-[#3F179B] to-[#5493A4] rounded"
              onClick={handleSubmit}
            >
              Save
            </button>
          </form>
        </div>
      </section>

      {/* Modal */}
      {modal.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md text-center">
            <p className={modal.isSuccess ? "text-green-500" : "text-red-500"}>
              {modal.message}
            </p>
            <button
              className="mt-4 py-1 px-4 bg-gradient-to-r from-[#3F179B] to-[#5493A4] rounded text-white"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
