import React, { useState } from "react";
import { Bell, Bookmark, ChevronLeft, ChevronRight, Save } from "react-feather";
import { useNavigate } from "react-router-dom";
import useBookmark from "../hooks/useBookmark";
import { imgUrl } from "../utils/constant";

const FeaturedSolution = ({ whitePapers, categoryData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === whitePapers?.length - 4 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? whitePapers?.length - 4 : prevIndex - 1
    );
  };
  return (
    <div className="relative overflow-hidden ">
      {whitePapers?.length ? (
        <>
          <h3 className="p-3">Related Whitepapers</h3>
          <div
            className="flex transition-all duration-500 ease-in-out"
            style={{ transform: `translateX(-${(currentIndex * 100) / 4}%)` }}
          >
            {whitePapers?.map((whitePaper) => (
              <WhitepaperCard
                key={whitePaper.solutionSet.id}
                whitePaper={whitePaper}
                categoryData={categoryData}
              />
            ))}
          </div>

          <ChevronLeft
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full text-[#4702a2] "
            onClick={prevSlide}
            size={40}
          />
          <ChevronRight
            className="absolute top-1/2 right-0 transform -translate-y-1/2  bg-white rounded-full  text-[#4702a2] "
            onClick={nextSlide}
            size={40}
          />
        </>
      ) : (
        <h3 className="p-3">No Related Whitepapers</h3>
      )}
    </div>
  );
};

const WhitepaperCard = ({ whitePaper, categoryData }) => {
  const { isSaved, handleBookmarkClick } = useBookmark(
    whitePaper.solutionSet.id,
    whitePaper?.isSaved ?? false
  );
  const navigate = useNavigate();

  return (
    <div className={`flex-none w-full sm:w-1/2 font-montserrat lg:w-1/4 px-2`}>
      <div className="bg-white shadow-lg overflow-hidden cursor-pointer">
        <div
          onClick={() => navigate(`/whitepaper/${whitePaper.solutionSet.id}`)}
        >
          <div
            className="w-full h-40 bg-cover bg-center"
            style={{
              backgroundImage: `url("${imgUrl}${whitePaper.solutionSet.imagePath}")`,
            }}
          ></div>
          <div className="p-4">
            <h3 className="text-xl font-bold mb-2 text-[#4702a2] font-montserrat inline-block border-b-2 border-[#4702a2] pb-1 w-full h-14">
              {whitePaper.solutionSet.title}
            </h3>

            <p className="text-black line-clamp-2 font-montserrat">
              {whitePaper.solutionSet.description}
            </p>
          </div>
        </div>
        <div
          className="flex justify-between items-center p-2 bg-[#042C54] text-white rounded-t-2xl"
          onClick={() => navigate(`/category/${categoryData.id}`)}
        >
          <h4 className="text-lg font-semibold line-clamp-1 font-montserrat">
            {categoryData?.name}
          </h4>
          {isSaved ? (
            <p
              className="text-white m-0 p-0 text-lg"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-fill"></i>
            </p>
          ) : (
            <p
              className="text-white m-0 p-0 text-lg"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-line"></i>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedSolution;
