import React, { useState } from "react";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "react-feather";
import { fadeIn } from "../utils/variants";
import { FeatureCard } from "./FeatureCard";

const Section2home = ({ featuredSolutionData }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const cardsPerPage = 8;

  const totalPages = Math.ceil(featuredSolutionData.length / cardsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentPageCards = () => {
    const startIndex = currentPage * cardsPerPage;
    return featuredSolutionData.slice(startIndex, startIndex + cardsPerPage);
  };

  return (
    <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="text-[#4702a2] text-3xl md:text-5xl lg:text-4xl font-bold mx-2 text-center font-montserrat"
        >
          POWERING YOUR BUSINESS WITH IN-DEPTH B2B RESOURCES
        </motion.h1>
        <motion.p
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="flex justify-center items-center text-lg md:text-2xl lg:text-lg text-center md:text-left leading-relaxed tracking-wide mx-2 font-montserrat"
        >
          Leverage our extensive platform to access the best whitepapers for
          your needs
        </motion.p>

        <div className="relative px-4 lg:px-28 mt-8">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2  lg:grid-cols-4  md:gap-3 lg:gap-4">
            {getCurrentPageCards().map((featureCard, index) => (
              <FeatureCard key={index} featureCard={featureCard} />
            ))}
          </div>

          {/* Navigation Controls */}
          <div className="flex justify-center items-center mt-8 gap-4">
            <button
              onClick={handlePrevious}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronLeft size={24} />
            </button>

            {/* Pagination Dots */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <div
                  key={index}
                  className={`h-3 w-3 rounded-full ${
                    currentPage === index ? "bg-purple-800" : "bg-gray-300"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNext}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const MostDowloaded = ({ mostDownloaded }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const cardsPerPage = 8;

  const totalPages = Math.ceil(mostDownloaded.length / cardsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentMostDownloadedCards = () => {
    const startIndex = currentPage * cardsPerPage;
    return mostDownloaded.slice(startIndex, startIndex + cardsPerPage);
  };

  return (
    <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="text-[#4702a2] text-3xl md:text-5xl lg:text-4xl font-bold mx-2 text-center font-montserrat"
        >
          Most Downloaded Whitepaper
        </motion.h1>

        <div className="relative px-4 lg:px-28 mt-8">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2  lg:grid-cols-4  md:gap-3 lg:gap-4">
            {getCurrentMostDownloadedCards().map((featureCard, index) => (
              <FeatureCard key={index} featureCard={featureCard} />
            ))}
          </div>

          {/* Navigation Controls */}
          <div className="flex justify-center items-center mt-8 gap-4">
            <button
              onClick={handlePrevious}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronLeft size={24} />
            </button>

            {/* Pagination Dots */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <div
                  key={index}
                  className={`h-3 w-3 rounded-full ${
                    currentPage === index ? "bg-purple-800" : "bg-gray-300"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNext}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const TrendingWhitepapers = ({ trendingWhitePapers }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const cardsPerPage = 8;

  const totalPages = Math.ceil(trendingWhitePapers.length / cardsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentTrendingWhitepapersCards = () => {
    const startIndex = currentPage * cardsPerPage;
    return trendingWhitePapers.slice(startIndex, startIndex + cardsPerPage);
  };

  return (
    <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="text-[#4702a2] text-3xl md:text-5xl lg:text-4xl font-bold mx-2 text-center font-montserrat"
        >
          Trending Whitepapers
        </motion.h1>

        <div className="relative px-4 lg:px-28 mt-8">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2  lg:grid-cols-4  md:gap-3 lg:gap-4">
            {getCurrentTrendingWhitepapersCards().map((featureCard, index) => (
              <FeatureCard key={index} featureCard={featureCard} />
            ))}
          </div>

          {/* Navigation Controls */}
          <div className="flex justify-center items-center mt-8 gap-4">
            <button
              onClick={handlePrevious}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronLeft size={24} />
            </button>

            {/* Pagination Dots */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <div
                  key={index}
                  className={`h-3 w-3 rounded-full ${
                    currentPage === index ? "bg-purple-800" : "bg-gray-300"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNext}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Section2home, MostDowloaded, TrendingWhitepapers };
