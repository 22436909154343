import React from "react";
import { useNavigate } from "react-router-dom";

const buttonData = [
  {
    id: 1,
    name: "ABM",
    style: {
      height: "45px",
      width: "220px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "20%",
      left: "31%",
      // left: '49%',
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"5px",
      marginBottom: "8px",
    },
  },
  {
    id: 2,
    name: "BIG DATA",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "75%",
      left: "13%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"40px",
      marginBottom: "8px",
    },
  },
  {
    id: 3,
    name: "CUSTOMER EXPERIENCE",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "75%",
      left: "49%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"5px",
      marginBottom: "8px",
    },
  },
  {
    id: 4,
    name: "ROBOTIC PROCESS AUTOMATION",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "39%",
      left: "13%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"40px",
      marginBottom: "8px",
    },
  },
  {
    id: 5,
    name: "AR & VR",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "44%",
      left: "48%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      marginLeft: "8px",
    },
  },
  {
    id: 6,
    name: "SERVER VIRTUALIZATION",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "62%",
      left: "13%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"40px",
      marginBottom: "8px",
    },
  },
  {
    id: 7,
    name: "HEALTHCARE",
    style: {
      height: "45px",
      width: "170px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "22%",
      left: "47%",
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      marginLeft: "8px",
    },
  },
  {
    id: 8,
    name: "DEVOPS",
    style: {
      height: "45px",
      width: "220px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "53%",
      left: "30%",
      // left: '49%',
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"5px",
      marginBottom: "8px",
    },
  },
  {
    id: 9,
    name: "CLOUD SECURITY",
    style: {
      height: "45px",
      width: "220px",
      backgroundColor: "transparent",
      color: "#fff",
      // border: '2px solid #fff',
      borderRadius: "5px",
      position: "absolute",
      top: "75%",
      left: "31%",
      // left: '49%',
      marginTop: "25px",
      transform: "translate(-50%, -50%)",
      // marginLeft:"5px",
      marginBottom: "8px",
    },
  },
  // {
  //   id: 1,
  //   label: "Anaerobic Digestion",
  //   style: {
  //     height: "45px",
  //     width: "220px",
  //     backgroundColor: "transparent",
  //     color: "#fff",
  //     borderRadius: "5px",
  //     position: "absolute",
  //     top: "20%",
  //     left: "31%",
  //     marginTop: "25px",
  //     transform: "translate(-50%, -50%)",
  //     marginBottom: "8px",
  //   },

  //   // onClick: (navigate) =>  navigate(`/category/${buttonData.label}`),
  // },
  // {
  //   id: 2,
  //   label: "Marketing Automation",
  //   style: {
  //     height: "45px",
  //     width: "170px",
  //     backgroundColor: "transparent",
  //     color: "#fff",
  //     borderRadius: "5px",
  //     position: "absolute",
  //     top: "75%",
  //     left: "13%",
  //     marginTop: "25px",
  //     transform: "translate(-50%, -50%)",
  //     marginBottom: "8px",
  //   },
  // },
  // Add more buttons as needed
  // {
  //   id: 10,
  //   name: "Explore More",
  //   style: {
  //     height: "45px",
  //     width: "170px",
  //     backgroundColor: "transparent",
  //     color: "#fff",
  //     borderRadius: "5px",
  //     position: "absolute",
  //     top: "70%",
  //     left: "69%",
  //     marginTop: "25px",
  //     transform: "translate(-50%, -50%)",
  //     marginLeft: "8px",
  //   },
  //   // onclick()=>"/whitepapers-set"

  // },
];

const Curated = ({ curatedWhitePapers }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="hidden md:block relative w-full h-full">
        {/* Background Image */}
        <img
          src={require("../assets/assetsFromyash/Curated.jpg")}
          alt="Hero Background"
          className="w-full h-full object-cover"
        />
        <button
          className="bg-gradient-to-r to-cyan-400 from-purple-800 rounded-lg opacity-75 group-hover:opacity-100 transition-opacity"
          style={{
            height: "45px",
            width: "170px",
            // backgroundColor: "transparent",
            color: "#fff",
            // border: '2px solid #fff',
            borderRadius: "5px",
            position: "absolute",
            top: "70%",
            left: "69%",
            marginTop: "25px",
            transform: "translate(-50%, -50%)",
            marginLeft: "8px",
          }}
          onClick={() => navigate("/whitepapers-set")}
        >
          Explore More
        </button>
        {curatedWhitePapers.map((item, index) => (
          <button
            key={item.id}
            style={buttonData[index].style}
            onClick={() => navigate(`/category/${item.id}`)}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="md:hidden flex flex-col py-8 px-3 w-full  bg-[#0E0C3B] text-white font-montserrat">
        <div className="grid grid-cols-2 gap-2 mb-5">
          {curatedWhitePapers.map((item, id) => (
            <button
              key={item.id}
              className="rounded-2xl border-2  border-white px-3 py-3 font-semibold uppercase text-white transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-md hover:shadow-[4px_4px_0px_white] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none"
            >
              {item.name}
            </button>
          ))}
        </div>
        <h1 className="text-[2rem] font-bold">CURATED WHITEPAPER SETS</h1>
        <p>
          Discover time-saving solutions with our expertly organized collection
          of proven strategies for tackling industry challenges.
        </p>
        <button
          className="w-40 rounded-md py-1 px-4 bg-gradient-to-r from-[#3F179B] to-[#5493A4] active:scale-95"
          onClick={() => navigate("/whitepapers-set")}
        >
          Explore More
        </button>
      </div>
    </>
  );
};

export default Curated;
