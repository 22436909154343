import React, { useState } from "react";

export default function InputPassword({
  type,
  className,
  placeholder,
  name,
  value,
  onChange,
}) {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  return (
    <div className="flex border rounded items-center px-2">
      <input
        type={isVisiblePassword ? "text" : "password"}
        className={className}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        required
      />
      <div>
        {isVisiblePassword ? (
          <span
            onClick={() => setIsVisiblePassword(!isVisiblePassword)}
            className=" cursor-pointer"
          >
            <i className="ri-eye-line"></i>
          </span>
        ) : (
          <span
            onClick={() => setIsVisiblePassword(!isVisiblePassword)}
            className=" cursor-pointer"
          >
            <i className="ri-eye-off-line"></i>
          </span>
        )}
      </div>
    </div>
  );
}
