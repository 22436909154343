import { motion } from "framer-motion";
import useBookmark from "../hooks/useBookmark";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "../utils/constant";
import { fadeIn } from "../utils/variants";

export const FeatureCard = ({ featureCard }) => {
  const { isSaved, handleBookmarkClick } = useBookmark(
    featureCard.id,
    featureCard?.isSavedByUser ?? false
  );

  const navigate = useNavigate();

  return (
    <motion.div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: true, amount: 0.7 }}
      whileHover={{
        scale: 1.02,
        transition: { ease: "easeOut", duration: 0.5 },
      }}
      className="w-full p-2 flex-none"
    >
      <div className="bg-white shadow-lg overflow-hidden h-full cursor-pointer">
        <div onClick={() => navigate(`/whitepaper/${featureCard.id}`)}>
          <div
            className="w-full h-60 bg-contain bg-no-repeat bg-center"
            style={{
              backgroundImage: `url("${imgUrl}${featureCard.imgSrc}")`,
            }}
          />
          <div className="px-2">
            <h3 className="text-sm font-bold mb-2 text-[#4702a2] font-montserrat inline-block border-b-2 border-[#4702a2] pb-1 w-full h-6 line-clamp-1">
              {featureCard.title}
            </h3>
            <p className="text-black text-sm line-clamp-3 h-14 font-montserrat">
              {featureCard.description || "No description available"}
            </p>
          </div>
        </div>
        <div
          className="flex justify-between items-center p-2 bg-[#042C54] text-white rounded-t-xl shadow-lg"
          onClick={() => navigate(`/category/${featureCard.category_id}`)}
        >
          <h4 className="text-sm font-semibold line-clamp-1 font-montserrat w-5/6 m-0">
            {featureCard.category?.replaceAll("-", " ") ||
              "No category available"}
          </h4>
          {isSaved ? (
            <p
              className="text-white cursor-pointer text-lg m-0 p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-fill"></i>
            </p>
          ) : (
            <p
              className="text-white cursor-pointer text-lg m-0 p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-line"></i>
            </p>
          )}
        </div>
      </div>
    </motion.div>
  );
};
