import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";

const BlogView = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);

  const [blog, setBlog] = useState({});

  useEffect(() => {
    const getBlogData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/blogs/get-blog/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });

        if (response.data.status) {
          setBlog(response.data.data);
        }
      } catch (error) {
        console.log("An error occurred while getting Blog Data.");
      }
    };

    getBlogData();
  }, [id]);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: blog?.blogContent,
        }}
      />
    </div>
  );
};

export default BlogView;
