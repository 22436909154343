import React from "react";
import { NavLink } from "react-router-dom";

import { User, Settings, Bookmark, Bell, Book } from "react-feather";
import { useSelector } from "react-redux";
import { imgUrl } from "../utils/constant";
import "./ProfileSidebar.css";

const ProfileSidebar = () => {
  return (
    <nav
      layout
      className="sticky top-0  md:w-[225px] h-[100vh] shrink-0 border-r border-slate-300 bg-white p-2 "
    >
      <TitleSection />

      <div className="space-y-1">
        <Option Icon={User} title="Dashboard" to="/profile-dashboard" />
        <Option
          Icon={Bookmark}
          title="Saved Whitepapers"
          to="/saved-whitepapers"
        />
        <Option
          Icon={Bell}
          title="Subscribed Category"
          to="/subscribed-category"
        />
        <Option Icon={Book} title="Newsletter" to="/profile-newsletter" />
        <Option Icon={Settings} title="Settings" to="/profile-settings" />
      </div>
    </nav>
  );
};

const Option = ({ Icon, title, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `relative flex h-10 w-full no-underline items-center rounded-md transition-colors ${
          isActive
            ? "bg-indigo-100 text-indigo-800"
            : "text-slate-500 hover:bg-slate-100"
        }`
      }
    >
      <div layout className="grid h-full w-10 place-content-center text-lg">
        <Icon />
      </div>

      <span
        layout
        initial={{ opacity: 0, y: 12 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.125 }}
        className="hidden md:block text-xs font-medium"
      >
        {title}
      </span>
    </NavLink>
  );
};
const TitleSection = () => {
  const username = useSelector((state) => state.user.username);

  return (
    <div className="mb-3 border-b border-slate-300 pb-3">
      <div className="flex flex-col py-2 gap-2 cursor-pointer items-center justify-between rounded-md transition-colors hover:bg-slate-100">
        <Profile />

        <div
          layout
          initial={{ opacity: 0, y: 12 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.125 }}
        >
          <span className="hidden md:block text-base font-semibold">
            {username}
          </span>
        </div>
      </div>
    </div>
  );
};

const Profile = () => {
  const profilePicture = useSelector((state) => state.user.profilePicture);

  return (
    <div
      layout
      className="grid size-10 md:size-28 shrink-0 text-white place-content-center rounded-full bg-[#4B49A2]"
    >
      {profilePicture ? (
        <div
          className="sidebar-img"
          style={{
            backgroundImage: `url(${imgUrl + profilePicture})`,
          }}
        ></div>
      ) : (
        <User />
      )}{" "}
    </div>
  );
};

export default ProfileSidebar;
